import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useQuery } from '@apollo/client';
import { get } from '../lib/nodash';
import { Box } from 'grommet';

import Seo from '../components/Seo';
import Loading from '../components/Loading';
import PreviewStory from '../components/DynamicPage/PreviewStory';
import { draftStoryblokClient } from '../gatsby-theme-apollo/client';
import { CONTENT_NODE } from '../queries/storyblokQueries';
import usePageBodyComponents from '../components/DynamicPage/usePageBodyComponents';

const FourOhFour = ({ location, data: { storyblokEntry = {}, site } }) => {
  const components = usePageBodyComponents(storyblokEntry);

  const { data, loading } = useQuery(CONTENT_NODE, {
    client: draftStoryblokClient,
    skip: !location.search.includes('_storyblok'),
    variables: {
      id: location.pathname.substring(1),
    },
  });

  return (
    <>
      <Seo
        title={
          get('content.metafields.title', storyblokEntry) ||
          get('title', storyblokEntry) ||
          '404 Page not found'
        }
        description={get('content.metafields.description', storyblokEntry)}
      />
      {loading ? (
        <Box pad="xlarge">
          <Loading />
        </Box>
      ) : (
        <>
          {get('ContentNode.id', data) ? (
            <PreviewStory
              storyId={get('ContentNode.id', data)}
              content={get('ContentNode.content', data)}
              site={site}
            />
          ) : (
            components
          )}
        </>
      )}
    </>
  );
};

FourOhFour.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
};

export default FourOhFour;

export const query = graphql`
  query FourOhFourPage {
    storyblokEntry(slug: { eq: "404" }) {
      id
      name
      full_slug
      content
      field_title_string
      internalId
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
