import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { storyblokEditable } from 'gatsby-source-storyblok';

import { useStoryblokState } from '../../lib/storyblok';
import PageBlogPost from '../Blog/PageBlogPost';
import usePageBodyComponents from './usePageBodyComponents';

const PreviewStory = ({ storyId, site, content }) => {
  const story = useStoryblokState({ internalId: storyId, content });
  const components = usePageBodyComponents(story);
  const isBlog =
    story?.full_slug?.includes('blog/') ||
    story?.full_slug?.includes('articles/');
  return (
    <Box {...storyblokEditable(story.content)}>
      {isBlog ? (
        <PageBlogPost
          story={story}
          title={story?.content?.title || story?.name}
          siteUrl={site?.siteMetadata?.siteUrl}
        />
      ) : (
        <div>{components}</div>
      )}
    </Box>
  );
};

PreviewStory.propTypes = {
  storyId: PropTypes.number,
  site: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
};

export default PreviewStory;
